import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
//import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description }) => {
  const {site} = useStaticQuery(query)
  const {
    siteDesc,
    siteTitle,
    author,
  } = site.siteMetadata

  return (
    <Helmet title={`${title} | ${siteTitle}`} htmlAttributes={{lang: "en"}}>
      <meta name='description' content={description || siteDesc} />
    </Helmet>
  )
}

const query = graphql`
  {
    site {
      siteMetadata {
        siteDescription: description
        siteTitle: title
        author
      }
    }
  }
`

export default SEO
